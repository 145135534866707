import React, { useState } from 'react';
import { FaLinkedinIn, FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FACEBOOK, INSTAGRAM, LINKEDIN } from 'utils/Socials';
import cn from 'classnames';
import styles from './styles.module.scss';

const SocialMediaButton = ({ link, icon }) => {
  const [hover, setHover] = useState(false);

  const renderIcon = () => {
    switch (icon) {
      case FACEBOOK:
        return <FaFacebookF className={cn(styles.icon, hover && styles.iconHover)} />;
      case LINKEDIN:
        return <FaLinkedinIn className={cn(styles.icon, hover && styles.iconHover)} />;
      case INSTAGRAM:
        return <FaInstagram className={cn(styles.icon, hover && styles.iconHover)} />;
      default:
        return <FaFacebookF className={cn(styles.icon, hover && styles.iconHover)} />;
    }
  };
  return (
    <a
      href={link}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={styles.SocialMediaButton}>
        {renderIcon()}
      </div>
    </a>
  );
};
export default SocialMediaButton;
