import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';

const Project = ({ project }) => {
  const {
    id,
    backgroundColor = null,
    imgSrc = '',
    title,
    description = '',
    features,
    tools,
    time,
  } = project;

  return (
    <>
      <div
        className={styles.expandedProjectContainer}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.15 } }}
          transition={{ duration: 0.2, delay: 0.15 }}
          style={{ pointerEvents: 'auto' }}
          className={styles.overlay}
        >
          <Link to='/' className={styles.overlayLink} />
        </motion.div>
        <Link to='/' className={styles.linkWrapper}>
          <motion.div
            layoutId={`expandable-card-${id}`}
            className={styles.expandedProject}
            style={backgroundColor && { backgroundColor }}
          >
            <motion.div
              layoutId={`card-image-container-${id}`}
              className={styles.projectImgContainer}
            >
              <img
                className={styles.projectImg}
                src={imgSrc}
                alt='project logo'
              />
            </motion.div>
            <motion.div className={styles.projectInfo}>
              <h1 className={styles.projectTitle}>{title}</h1>
              <p className={styles.projectDescription}>{description}</p>
              <p className={styles.projectDescription}>{features}</p>
              {time && (
                <>
                  <p className={styles.subtitle}>Time Spent with the project: </p>
                  <p className={styles.projectTime}>{time}</p>
                </>
              )}

              {tools?.length > 0 && (
                <>
                  <h2 className={styles.subtitle}>Tools used in the project</h2>
                  <div className={styles.projectToolsContainer}>
                    {tools?.map(tool => (
                      <span key={tool} className={styles.projectTool}>{tool}</span>
                    ))}
                  </div>
                </>
              )}
            </motion.div>
          </motion.div>
        </Link>
      </div>
    </>
  );
};
export default Project;
