import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const ProjectCard = ({
  project, hobbyProject, activeProject, isActive,
}) => {
  const {
    id, imgSrc = '', backgroundColor = null,
  } = project;
  return (
    <motion.li
      className={styles.projectContainer}
      style={backgroundColor && { backgroundColor }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}

    >
      <motion.div
        layoutId={`expandable-card-${id}`}
      >
        <motion.div
          layoutId={`card-image-container-${id}`}
          className={styles.projectImgContainer}
        >
          <img
            className={styles.projectImg}
            src={imgSrc}
            alt='project logo'
          />

        </motion.div>
      </motion.div>
      {!activeProject && !isActive && (
        <Link to={`${hobbyProject ? 'hobby' : 'work'}/${id}`} className={styles.cardLink} />
      )}
    </motion.li>
  );
};

export default ProjectCard;
