import React from 'react';
import SocialMediaButton from 'components/SocialMediaButton/SocialMediaButton';
import { FACEBOOK, INSTAGRAM, LINKEDIN } from 'utils/Socials';
import styles from './styles.module.scss';

const SocialMedia = () => (
  <div className={styles.socialMediaContainer}>
    <SocialMediaButton
      link='https://www.linkedin.com/in/mohamed-omar-said/'
      icon={LINKEDIN}
    />
    <SocialMediaButton
      link='https://www.facebook.com/moa.os'
      icon={FACEBOOK}
    />
    <SocialMediaButton
      link='https://www.instagram.com/moa.os/'
      icon={INSTAGRAM}
    />
  </div>
);

export default SocialMedia;
