import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const FlipImage = ({ frontImg, backImg, hover }) => (
  <div className={cn(styles.flipImageContainer)}>
    <div className={cn(styles.flipImageInner, hover && styles.flipImg)}>
      <div className={styles.flipImageFront}>
        <img
          src={frontImg}
          alt='me'
          className={styles.flipImage}
        />
      </div>
      <div className={styles.flipImageBack}>
        <img
          src={backImg}
          alt='me'
          className={styles.flipImage}
        />
      </div>
    </div>
  </div>
);

export default FlipImage;
