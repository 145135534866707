import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

const Hexagon = ({ children, classNames }) => (
  <div className={styles.hexagonWrapper}>
    <div className={cn(styles.hexagon, classNames)}>
      <div className={styles.hexagonContent}>
        {children}
      </div>
    </div>
  </div>
);

export default Hexagon;
