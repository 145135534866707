import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { HexagonWithHover, Projects } from 'components';
import { Route } from 'react-router-dom';
import {
  FaGamepad, FaMobileAlt, FaLaptopCode, FaInfinity,
} from 'react-icons/fa';
import WorkPorjects from 'utils/WorkPorjects';
import HobbyProjects from 'utils/HobbyProjects';

import styles from './styles.module.scss';

const Tools = [
  {
    name: 'Responsive Websites',
    icon: <FaLaptopCode className={styles.iconSize} />,
  },
  {
    name: 'Mobile Apps',
    icon: <FaMobileAlt className={styles.iconSize} />,
  },
  {
    name: 'Animations',
    icon: <FaInfinity className={styles.iconSize} />,
  },
  {
    name: 'Game Dev',
    icon: <FaGamepad className={styles.iconSize} />,
  },
];

const About = () => {
  const [isActive, setIsActive] = useState(false);
  const toggleIsActive = state => setIsActive(state);
  return (
    <div className={styles.pageContainer}>
      <div className={styles.toolsContainer}>
        <Fade cascade triggerOnce>
          {Tools?.length > 0 && Tools.map(tool => (
            <div key={tool.name} className={styles.toolWrapper}>
              <HexagonWithHover
                hexagonChildren={() => tool.icon}
                text={tool.name}
                key={tool.name}
              />
            </div>
          ))}
        </Fade>
      </div>
      <Route path={['/work/:id', '/']}>
        <Projects
          workProjects={WorkPorjects}
          isActive={isActive}
          toggle={toggleIsActive}
          hobbyProjects={HobbyProjects}
        />
      </Route>
    </div>
  );
};
export default About;
