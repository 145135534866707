import React from 'react';
import { About, Home } from 'pages';
import Particles from 'react-particles-js';

import styles from './styles.module.scss';

const particlesConfig = {
  particles: {
    number: {
      value: 100,
      density: {
        enable: false,
      },
    },
    size: {
      value: 5,
      random: true,
      anim: {
        speed: 4,
        size_min: 2,
      },
    },
    color: {
      value: '#ea00d9',
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'bottom',
      out_mode: 'out',
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 1,
      },
      repulse: {
        distance: 100,
        duration: 4,
      },
    },
  },
};

const Router = () => (
  <div className={styles.routerContainer}>
    <Particles params={particlesConfig} className={styles.particlesWrapper} />

    <div className={styles.pageContainer}>
      <Home />
      <About />
    </div>
  </div>
);

export default Router;
