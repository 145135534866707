import MoaPixel from 'assets/images/moaPixel.jpg';
import Vive from 'assets/images/vive.png';
import LaneSwap from 'assets/images/laneSwap.png';
import QuestionMark from 'assets/images/questionMark.png';

export default [
  {
    id: 'newGame',
    title: 'Soon',
    imgSrc: QuestionMark,
    backgroundColor: '#b2b2b2',
    tools: ['Unity'],
    description: 'A new game that I am making, still in planning phase.',
  },
  {
    id: 'moa',
    title: 'Moa',
    imgSrc: MoaPixel,
    backgroundColor: '#022521',
    tools: ['Reactjs', 'Framer motion'],
    description: 'My personal website, to show my work.',
  },
  {
    id: 'mes',
    title: 'MES',
    imgSrc: Vive,
    backgroundColor: '#b2b2b2',
    tools: ['Unnreal engine', 'VR'],
    description: 'My bachelor project. I made a game with two other students that I wrote my thises with.',
    features: `The purpose of the game was to teach elementry school students how to code.
    So you pun on the VR headset and try to solve coding problems`,
  },
  {
    id: 'laneswap',
    title: 'Moa',
    imgSrc: LaneSwap,
    backgroundColor: '#ebdbb7',
    tools: ['Unity'],
    description: `A top down car game that I made while studying.
    The game was available on Google play store.`,
  },
];
