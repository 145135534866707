import NTHlogo from 'assets/images/nth.png';
import Innitlogo from 'assets/images/innit.png';
import NorskTippingLogo from 'assets/images/norskTipping.png';
import Intersport from 'assets/images/intersport.png';
import Moicon from 'assets/images/moicon.png';
import ck from 'assets/images/ck.png';
import QuestionMark from 'assets/images/questionMark.png';

export default [
  {
    id: 'soon',
    title: '??????',
    imgSrc: QuestionMark,
    backgroundColor: '#048eb3',
    tools: [],
    description: 'Coming Soon!',
  },
  {
    id: 'moicon',
    title: 'Moicon',
    imgSrc: Moicon,
    backgroundColor: '#0a0b0e',
    tools: ['Gatsbyjs', 'Sanity', 'Animations'],
    description: `I have been part of the team helping Moicon develop their website at Innit.
     Moicon is a company working with building managers, manufacturers, architects and designers to transform their businesses through innovative Digital Twin technology
    `,
    time: 'oct/20 - Now',

  },
  {
    id: 'stempeljakten',
    title: 'Stempeljakten',
    imgSrc: ck,
    backgroundColor: '#ffffff',
    tools: ['React Native', 'Animations', 'React Native elements', 'Native Bridging'],
    description: `Stempeljakten is cickle K's summer campaign app here in Norway.
    `,
    features: `The purpose of the app was to reward customers who visits circle K's stations often.
    The user uses the app to scan a QR code whenever they visit a station to collect a stamp, which can be converted to prizes`,
    time: 'mars/20 - jun/20',

  },
  {
    id: 'intersport',
    title: 'Intersport',
    imgSrc: Intersport,
    backgroundColor: '#ffffff',
    tools: ['React Native', 'Laravel', 'Redux', 'Javascript', 'php'],
    description: `I helped the team developing the intersport app for a short periode, where new functionaly was needed.
    In addition this was the first time I tried to make some simple routes using Laravel and php`,
    time: 'jun/20 - oct/20',
  },
  {
    id: 'innit',
    title: 'innit.no',
    imgSrc: Innitlogo,
    backgroundColor: '#172727',
    tools: ['Reactjs', 'Sanity', 'Framer motion', 'GA', 'Hotjar'],
    description: `The company's homepage. I was part of the team that created the new version of the site.
    `,
    time: 'dec/19 - feb/20',

  },
  {
    id: 'norsktipping',
    title: 'Norsk tipping',
    imgSrc: NorskTippingLogo,
    backgroundColor: '#ffffff',
    tools: ['Reactjs'],
    description: `I was part of the Innit team at Norsk tipping for a year, where I worked on some parts of norsk-tipping.no.
    We helped Norsk-tipping move the lottry games into a reactJs application. 
    `,
    time: 'jan/18 - dec/19)',

  },
  {
    id: 'hockey',
    title: 'Hockey Apps',
    imgSrc: NTHlogo,
    backgroundColor: '#ffffff',
    description: `My first project at Innit. I was part of the team that started working with Storhamar Hockey app and then expended to other clubs.
      The app exists for more then half of the clubs in the norwagian hockey league today.    
    `,
    features: `The app is now the main platform for fan egagement and ticketsales. Fans get to read the latest news about the club, see match results and buy match tickets.
    In addition to click & collect.. and other funtionalities. The app is now being maintained by another team`,
    time: '(aug/18 - jan/19) (jan/20 - april/20)',
    tools: [
      'React Native',
      'Easy Dev tools',
      'Animation',
      'Native Bridging',
      'Fastlane',
    ],
  },
];
