import React from 'react';
import { AboutMe, SocialMedia } from 'components';
import styles from './styles.module.scss';

const Home = () => (
  <div className={styles.pageContainer}>
    <AboutMe />
    <SocialMedia />
  </div>
);

export default Home;
