import React, { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { Slide, Fade } from 'react-awesome-reveal';
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import Project from 'components/Project/Project';
import ProjectCard from 'components/ProjectCard/ProjectCard';

import styles from './styles.module.scss';

const Projects = ({
  workProjects, hobbyProjects, isActive,
}) => {
  const { id } = useParams();
  const [allProjects, setAllProjects] = useState([]);

  useEffect(() => {
    const tempProjects = workProjects.concat(hobbyProjects);
    setAllProjects(tempProjects);
  }, [workProjects, hobbyProjects]);
  return (
    <>
      <div className={styles.projectsWrapper}>
        <Slide direction='up' triggerOnce>
          <h2>Work projects</h2>
        </Slide>
        <AnimateSharedLayout type='crossfade'>
          <ul className={styles.projectsContainer}>
            <Fade triggerOnce>
              {workProjects?.length && workProjects.map(item => (
                <ProjectCard
                  project={item}
                  key={item.id}
                  activeProject={id}
                  isActive={isActive}
                />
              ))}
            </Fade>
          </ul>
          <div className={styles.paddingTop}>
            <Slide direction='down' triggerOnce>
              <h2>Hobby projects</h2>
            </Slide>
            <ul className={styles.projectsContainer}>
              <Fade triggerOnce>
                {hobbyProjects?.length && hobbyProjects.map(item => (
                  <ProjectCard
                    project={item}
                    key={item.id}
                    activeProject={id}
                    isActive={isActive}
                  />
                ))}
              </Fade>
            </ul>
          </div>
          <AnimatePresence>
            {id && (
            <Project
              id={id}
              project={allProjects.find(item => item.id === id) || workProjects[0]}
              key='item'
            />
            )}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    </>
  );
};
export default Projects;
