import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import Hexagon from '../Hexagon/Hexagon';
import styles from './styles.module.scss';

const HexagonWithHover = ({ text, hexagonChildren }) => {
  const [hover, setHover] = useState(false);
  return (
    <button
      onMouseEnter={() => !isMobile && setHover(true)}
      onMouseLeave={() => !isMobile && setHover(false)}
      onClick={() => setHover(prev => !prev)}
      type='button'
      className={styles.hexagonButton}
    >
      <Hexagon classNames={cn(styles.hexagon1, hover && styles.onHoverHexagon)}>
        {hexagonChildren()}
      </Hexagon>
      <div className={cn(styles.textContainer, hover && styles.onHoverText)}>
        {text}
      </div>
    </button>
  );
};
export default HexagonWithHover;
