import React, { useState } from 'react';
import cn from 'classnames';
import moaImage from 'assets/images/moa.jpg';
import moaPixel from 'assets/images/moaPixel.jpg';
import FlipImage from 'components/FlipImage/FlipImage';
import styles from './styles.module.scss';

const AboutMe = () => {
  const [hover, setHover] = useState(false);

  const renderInfo = () => (
    <div className={styles.infoText}>
      I&apos;m
      <span className={cn(styles.glowingText, styles.italicText)}> Moa</span>
      , a software developer for Innit AS in Hamar, Norway.
      I have background working with
      <span className={styles.italicText}> websites</span>
      ,
      <span className={styles.italicText}> mobile apps</span>
      {' '}
      and
      <span className={styles.italicText}> games</span>
      .
    </div>
  );
  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setHover(prev => !prev)}
      type='button'
      className={styles.aboutMeConatiner}
    >
      <FlipImage frontImg={moaPixel} backImg={moaImage} hover={hover} />
      <div className={styles.infoContainer}>
        <h1 className={cn(styles.infoTitle, styles.glowingText)}>Hello world!</h1>
        {renderInfo()}
      </div>
    </button>
  );
};

export default AboutMe;
